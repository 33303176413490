import React from 'react';

import { Svg } from 'src/components/Svg/Svg';

import { ProductListItem } from './utils/productListItem';
import classes from './ItemExtras.scss';

export function ItemExtras(props: { product: ProductListItem }) {
    const { product } = props;
    return (
        <span className={classes.delivery}>
            {/*<span className={[classes.deliveryItem, product.delivery ? classes.deliveryItemActive : ''].join(' ')}>*/}
            <span className={classes.item} data-active={String(product.delivery)}>
                <Svg name={product.delivery ? 'stock-available' : 'stock-unavailable'} /> Delivery
            </span>
            <span className={classes.item} data-active={String(product.click_collect)}>
                <Svg name={product.click_collect ? 'stock-available' : 'stock-unavailable'} /> Click & Collect
            </span>
        </span>
    );
}
